export interface GoogleAnalyticsEvent {
  category: GoogleAnalyticsCategory;
  action: GoogleAnalyticsAction;
  label: GoogleAnalyticsLabel | string; // Label can be used to send additional data like params, results etc
}

// Global Component or Specific Page
export enum GoogleAnalyticsCategory {
  PAGE_VISIT = 'PAGE_VISIT', // Used by the router
  TOOLBAR = 'TOOLBAR',
  MENU = 'MENU',
  LADING_PAGE = 'LADING_PAGE',
  CHARGEPORT_PAGE = 'CHARGEPORT_PAGE',
  CHARGING_STATION_PAGE = 'CHARGING_STATION_PAGE',
  PAYMENT_OPTION_PAGE = 'PAYMENT_OPTION_PAGE',
  PAYMENT = 'PAYMENT',
  CHARGING_OVERLAY = 'CHARGING_OVERLAY',
  CHARGING = 'CHARGING',
  SUMMARY = 'SUMMARY',
}

export enum GoogleAnalyticsAction {
  URL_CHANGED = 'URL_CHANGED', // Used by the router
  BACK = 'BACK',
  SEARCH = 'SEARCH',
  TOGGLE_MENU = 'TOGGLE_MENU',
  PAYPAL = 'PAYPAL',
  CREDITCARD = 'CREDITCARD',
  PREPARE_CHARGING = 'PREPARE_CHARGING',
  OPEN_DETAILS = 'OPEN_DETAILS',
  HELP = 'HELP',
  CHARGEPORT_SELECTED = 'CHARGEPORT_SELECTED',
  PAY_AND_START = 'PAY_AND_START',
  SESSION = 'SESSION',
  PAY_ONE = 'PAY_ONE',
  STRIPE = 'STRIPE',
  COLLAPSE = 'COLLAPSE',
  STOP_CHARGING = 'STOP_CHARGING',
  DOWNLOAD = 'DOWNLOAD',
  OPENED_PAGE = 'OPENED_PAGE',
}

export enum GoogleAnalyticsLabel {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface GoogleAnalyticsEventWrapper {
  event: Array<{
    eventInfo: {
      eventCategory: GoogleAnalyticsCategory;
      eventAction: GoogleAnalyticsAction;
      eventLabel: GoogleAnalyticsLabel | string;
    };
  }>;
}
