import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { EopIconModule } from '../../shared/components/eop-icon/eop-icon.module';
import { TariffModule } from '../../shared/components/tariff/tariff.module';
import { MaterialDesignModule } from '../../shared/modules/material-design/material-design.module';
import { ComponentsRoutingModule } from '../components-routing.module';
import { CarouselModule } from './carousel/carousel.module';
import { ChargingOverlayComponent } from './charging-overlay.component';
import { PdfDownloadComponent } from './summary/pdf-download/pdf-download.component';
import { SummaryComponent } from './summary/summary.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsRoutingModule,
    MaterialDesignModule,
    EopIconModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    RouterModule,
    CarouselModule,
    NgxHmCarouselModule,
    TariffModule,
    DirectivesModule,
  ],
  declarations: [ChargingOverlayComponent, SummaryComponent, PdfDownloadComponent],
  exports: [ChargingOverlayComponent, SummaryComponent, PdfDownloadComponent],
  providers: [DecimalPipe, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChargingOverlayModule {}
