import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { OpenPageModel } from '../../model/open-page.model';
import { OpenPageService } from '../../services/open-page.service';

@Component({
  selector: 'app-help-details',
  templateUrl: './help-details.component.html',
  styleUrls: ['./help-details.component.scss'],
})
export class HelpDetailsComponent implements OnInit {
  openPage: OpenPageModel;
  detailsShown = false;

  @Input()
  empSpecificContents: any;

  lang: string;

  constructor(public translate: TranslateService, public openPageService: OpenPageService) {}

  ngOnInit() {
    this.openPageService.currentOpenPage.subscribe(openPage => {
      this.openPage = openPage;
    });
  }

  public closeHelp(): void {
    this.openPage.helpOpen = false;
    this.openPage.selectedPageOpen = false;
    this.openPageService.changeOpenPage(this.openPage);
  }
}
