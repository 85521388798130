import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, concat, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { retryWhen, delay, take, switchMap } from 'rxjs/operators';

import { DialogComponent } from '../components/dialog/dialog.component';
import {
  ChargingSession,
  SESSION_DETAILED_STATUS,
  SESSION_STATUS,
} from '../models/bff/charging-session.interface';
import { SessionRating } from '../models/bff/session-rating.interface';
import { Tariff } from '../models/bff/station-data.interface';
import { LiveDataService } from './live-data.service';
import { LoadingIndicatorService } from './loading-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public subject = new Subject<ChargingSession>();
  private existence = new Subject<boolean>();
  public chargingSession: ChargingSession;
  public sessionId;

  public pollInterval;
  public interval = 30000;
  public intervalType: null | 'short' | 'long' | 'bdr1' | 'bdr2' = null;

  public sessionCall: Subscription;
  public status: Status;

  public connectionTimeout;
  public connectionTimeoutTime = 45000;

  public bdrTimeout;
  public bdrTimeoutTime1 = 30000;
  public bdrTimeoutTime2 = this.bdrTimeoutTime1 * 2;

  public readonly isOverlayCollapsed$ = new BehaviorSubject<boolean>(false);

  stopSuccess = null;

  constructor(
    public http: LiveDataService,
    public loadingIndicatorService: LoadingIndicatorService,
    public dialog: MatDialog
  ) {
    this.init();
  }

  init() {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      if (localStorage.getItem('stopSuccess')) {
        this.stopSuccess = localStorage.getItem('stopSuccess') === 'true';
        if (this.stopSuccess) {
          this.status = Status.STOPPING;
        }
      }

      this.setSessionId(sessionId);
    }

    this.subject.subscribe(chargingSession => {
      this.chargingSession = chargingSession;
    });
  }

  isOverlayCollapsed(isOverlayCollapsed: boolean) {
    this.isOverlayCollapsed$.next(isOverlayCollapsed);
  }

  getSessionSubscription(): Observable<ChargingSession> {
    return this.subject.asObservable();
  }

  getSessionExistence(): boolean {
    return !!this.getSessionId();
  }

  getSessionExistenceSubscription(): Observable<boolean> {
    return this.existence.asObservable();
  }

  getSessionId(): string {
    return localStorage.getItem('sessionId');
  }

  setSessionId(sessionId: string) {
    this.loadingIndicatorService.setIndicator(true);
    this.sessionId = sessionId;
    this.existence.next(true);
    this.status = Status.PAYMENT;
    this.updateSession();
  }

  startInterval(type: 'short' | 'long' | 'bdr1' | 'bdr2') {
    if (this.intervalType !== type) {
      this.intervalType = type;
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
        this.pollInterval = null;
      }
      let interval = 1000;

      switch (type) {
        case 'short':
          interval = this.interval / 6;
          break;
        case 'long':
          interval = this.interval;
          break;
        case 'bdr1':
          interval = this.bdrTimeoutTime1;
          break;
        case 'bdr2':
          interval = this.bdrTimeoutTime2;
          break;
      }
      const self = this;
      self.updateSession();
      this.pollInterval = setInterval(() => {
        self.updateSession();
      }, interval);
    }
  }

  fetchSessionRating(sessionId: string): Observable<SessionRating> {
    return this.http.get<SessionRating>(`/charging-session/${sessionId}/rating`);
  }

  isBdrAvailable(sessionId: string): Observable<boolean> {
    const RETRY_COUNT = 5;
    const RETRY_DELAY = 5000;
    return this.http
      .get<ChargingSession>('/charging-session/' + sessionId)
      .pipe(
        switchMap(chargingSession =>
          chargingSession?.bdrId ? of(true) : throwError('No bdr id yet')
        )
      )
      .pipe(
        retryWhen(error =>
          error.pipe(delay(RETRY_DELAY), take(RETRY_COUNT), obs =>
            concat(
              obs,
              throwError(`Number of retries (${RETRY_COUNT}) exceeded, show zero values.`)
            )
          )
        )
      );
  }

  updateSession(): void {
    this.sessionCall = this.http
      .get<ChargingSession>('/charging-session/' + this.sessionId)
      .subscribe(
        session => {
          this.loadingIndicatorService.resetIndicator();
          this.stopConnectionTimeOut();
          this.handleStatus(session);
        },
        error => {
          this.loadingIndicatorService.resetIndicator();
          const chargingSession = this.chargingSession
            ? this.chargingSession
            : {
                detailedStatus: null,
                status: null,
                vatId: null,
                date: null,
                receiptNumber: null,
                terminated: false,
                consumption: null,
                duration: null,
                savingsCO2: null,
                bdrId: null,
              };
          chargingSession.status = SESSION_STATUS.ERROR;
          chargingSession.detailedStatus = SESSION_DETAILED_STATUS.CONNECTION_FAILED;
          this.subject.next(chargingSession);
          this.startConnectionTimeOut();
          this.startInterval('short');
        }
      );
  }

  handleStatus(session: ChargingSession) {
    if (session.status === SESSION_STATUS.ERROR) {
      this.status = Status.ERROR;
      this.handleError(session);
    } else {
      switch (session.status) {
        case SESSION_STATUS.AUTHORIZATION_PENDING:
        case SESSION_STATUS.START_AUTHORIZED:
        case SESSION_STATUS.START_REQUESTED:
          this.status = Status.PAYMENT;
          this.startInterval('short');
          break;
        case SESSION_STATUS.STARTED:
          this.status = Status.READY;
          this.startInterval('short');
          break;
        case SESSION_STATUS.CHARGING:
          if (this.stopSuccess !== true) {
            this.status = Status.CHARGING;
            this.startInterval('long');
          }
          break;
        case SESSION_STATUS.STOP_AUTHORIZED:
        case SESSION_STATUS.STOP_REQUESTED:
          this.status = Status.STOPPING;
          session.terminated = true;
          this.startInterval('short');
          break;
        case SESSION_STATUS.STOPPED:
        case SESSION_STATUS.ENDED:
          this.status = Status.STOPPED;
          if (
            session.detailedStatus === SESSION_DETAILED_STATUS.ABORTED ||
            session.detailedStatus === SESSION_DETAILED_STATUS.PERISHED
          ) {
            if (this.stopSuccess === true && !this.chargingSession) {
              this.clearSession();
              const DialogRef = this.dialog.open(DialogComponent, {
                data: {
                  text: 'session.dialog.' + session.detailedStatus,
                  button2: 'session.error.ok',
                },
              });
            } else {
              this.clearPolling();
              this.clearTimeouts();
            }
          } else if (session.detailedStatus === SESSION_DETAILED_STATUS.SDR_INVALID) {
            session.terminated = true;
            this.clearPolling();
            this.clearTimeouts();
          } else {
            this.wait2MinForBDR();
          }
          break;
        case SESSION_STATUS.CLOSED:
          this.status = Status.STOPPED;
          this.clearPolling();
          this.clearTimeouts();
          break;
      }
      if (this.stopSuccess) {
        session.terminated = true;
      }
      this.subject.next(session);
    }
  }

  handleError(session: ChargingSession) {
    this.clearPolling();
    this.clearTimeouts();
    switch (session.detailedStatus) {
      case SESSION_DETAILED_STATUS.AUTHORIZATION_FAILED:
      case SESSION_DETAILED_STATUS.START_REQUEST_FAILED:
      case SESSION_DETAILED_STATUS.START_ACTIVATION_FAILED:
        this.openErrorDialog('session.error.tryagain', true, false);
        break;
      case SESSION_DETAILED_STATUS.CHARGING_FAILED:
        this.openErrorDialog('session.error.chargingfailed', false, true);
        break;
      case SESSION_DETAILED_STATUS.STOP_REQUEST_FAILED:
      case SESSION_DETAILED_STATUS.STOP_ACTIVATION_FAILED:
        this.openErrorDialog('session.error.viacar', false, false);
        break;
      case SESSION_DETAILED_STATUS.UNKNOWN_ERROR:
        switch (this.status) {
          case Status.PAYMENT:
            this.openErrorDialog('session.error.tryagain', true, false);
            break;
          case Status.CHARGING:
            this.openErrorDialog('session.error.chargingfailed', false, true);
            break;
          case Status.STOPPING:
            this.openErrorDialog('session.error.viacar', false, false);
            break;
          default:
            this.openErrorDialog('UNKNOWN', false, true);
        }
        break;
      default:
        this.openErrorDialog('UNKNOWN', false, true);
    }
  }

  openErrorDialog(text, clearSession, terminate) {
    this.loadingIndicatorService.resetIndicator();
    const DialogRef = this.dialog.open(DialogComponent, {
      data: {
        headline: 'session.error.headline',
        text,
        button2: 'session.error.ok',
      },
    });

    DialogRef.afterClosed().subscribe(result => {
      if (clearSession) {
        this.clearSession();
      } else if (terminate && this.chargingSession) {
        this.chargingSession.terminated = true;
        this.subject.next(this.chargingSession);
      }
    });
  }

  startConnectionTimeOut() {
    if (!this.connectionTimeout) {
      this.connectionTimeout = setInterval(() => {
        this.loadingIndicatorService.resetIndicator();
        this.clearPolling();
        this.clearTimeouts();
        const DialogRef = this.dialog.open(DialogComponent, {
          data: {
            headline: 'session.error.headline',
            text: 'session.error.timeout',
            button1: 'session.error.delete',
            button2: 'session.error.retry',
          },
          disableClose: true,
        });

        DialogRef.afterClosed().subscribe(result => {
          if (result === 'yes') {
            this.clearSession();
          } else {
            this.connectionTimeout = null;
            this.startInterval('short');
            this.startConnectionTimeOut();
          }
        });
        clearInterval(this.connectionTimeout);
      }, this.connectionTimeoutTime);
    }
  }

  stopConnectionTimeOut() {
    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
  }

  wait2MinForBDR() {
    if (this.intervalType !== 'bdr2' && this.intervalType !== 'bdr1') {
      this.startInterval('bdr1');
      const self = this;
      this.bdrTimeout = setInterval(() => {
        self.wait10MinForBDR();
      }, 1000 * 60 * 2);
    }
  }

  wait10MinForBDR() {
    if (this.intervalType !== 'bdr2') {
      this.startInterval('bdr2');
      const self = this;
      clearInterval(this.bdrTimeout);
      this.bdrTimeout = setInterval(() => {
        self.clearPolling();
        clearInterval(this.bdrTimeout);
      }, 1000 * 60 * 10);
    }
  }

  stopBDRTimeout() {
    if (this.bdrTimeout) {
      this.clearPolling();
      clearTimeout(this.bdrTimeout);
      this.bdrTimeout = null;
    }
  }

  clearPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.pollInterval = null;
      this.intervalType = null;
    }
    if (this.sessionCall) {
      this.sessionCall.unsubscribe();
    }
  }

  clearTimeouts() {
    this.stopConnectionTimeOut();
    this.stopBDRTimeout();
  }

  stopSession() {
    this.status = Status.STOPPING;
    this.loadingIndicatorService.setIndicator(true);
    this.clearPolling();
    this.clearTimeouts();

    this.http.post(`/charging-session/${this.sessionId}/stop`).subscribe(
      stop => {
        this.onStopSuccess();
      },
      error => {
        this.onStopError();
      }
    );
  }

  onStopSuccess() {
    this.stopSuccess = true;
    localStorage.setItem('stopSuccess', 'true');

    this.http.get<ChargingSession>('/charging-session/' + this.sessionId).subscribe(
      session => {
        if (
          session.status !== SESSION_STATUS.AUTHORIZATION_PENDING &&
          session.status !== SESSION_STATUS.START_AUTHORIZED &&
          session.status !== SESSION_STATUS.START_REQUESTED &&
          session.status !== SESSION_STATUS.STARTED
        ) {
          // Charging took place
          if (this.chargingSession) {
            this.chargingSession.terminated = true;
            this.subject.next(this.chargingSession);
          }
          this.loadingIndicatorService.resetIndicator();
          this.startInterval('short');
        } else {
          // Before charging
          this.clearSession();
          const DialogRef = this.dialog.open(DialogComponent, {
            data: {
              text: 'session.dialog.ABORTED',
              button2: 'session.error.ok',
            },
          });
        }
      },
      error => {
        this.chargingSession.terminated = true;
        this.subject.next(this.chargingSession);
        this.loadingIndicatorService.resetIndicator();
        this.startInterval('short');
      }
    );
  }

  onStopError() {
    this.loadingIndicatorService.resetIndicator();

    // on first attempt this.stopSuccess is null
    if (this.stopSuccess === false) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          headline: 'session.error.headline',
          text: 'session.error.stop',
          button1: 'session.error.delete',
          button2: 'session.error.ok',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'yes') {
          this.clearSession();
        } else {
          this.startInterval('short');
        }
      });
    } else {
      this.stopSuccess = false;
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          headline: 'session.error.headline',
          text: 'session.error.stop',
          button2: 'session.error.ok',
        },
      });
      this.startInterval('short');
    }
  }

  clearSession() {
    this.clearPolling();
    this.clearTimeouts();
    this.subject.next(null);
    this.existence.next(false);
    localStorage.removeItem('sessionId');
    localStorage.removeItem('tariff');
    localStorage.removeItem('chargePort');
    localStorage.removeItem('amount');
    localStorage.removeItem('stopSuccess');
    localStorage.removeItem('publicKey');
    localStorage.removeItem('station-info');
    this.sessionId = null;
    this.status = null;
    this.stopSuccess = null;
    this.isOverlayCollapsed$.next(false);
  }
}

export enum Status {
  'PAYMENT' = 'PAYMENT',
  'READY' = 'READY',
  'CHARGING' = 'CHARGING',
  'STOPPING' = 'STOPPING',
  'STOPPED' = 'STOPPED',
  'ERROR' = 'ERROR',
}
