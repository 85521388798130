import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IBrandingData, ICiData } from '../models/branding-data';

@Injectable({
  providedIn: 'root',
})
export class DynamicContentsService {
  private subject = new Subject<any>();

  constructor() {}

  public getDynamicContents(): Observable<IBrandingData> {
    return this.subject.asObservable();
  }

  public setDynamicContents(data: ICiData, lang: string) {
    let imprint = '';
    let contact = '';
    let instruction = '';
    let privacy = '';
    let terms = '';

    if (data.imprint) {
      imprint = data.imprint[lang] || data.imprint['en'] || '';
    }
    if (data.contact) {
      contact = data.contact[lang] || data.contact['en'] || '';
    }
    if (data.instruction) {
      instruction = data.instruction[lang] || data.instruction['en'] || '';
    }
    if (data.privacy) {
      privacy = data.privacy[lang] || data.privacy['en'] || '';
    }
    if (data.terms) {
      terms = data.terms[lang] || data.terms['en'] || '';
    }

    const ciData: IBrandingData = Object.assign(
      {},
      {
        // theme colors
        colors: {
          buttonBackgroundColor: data.buttonBackgroundColor,
          buttonTextColor: data.buttonTextColor,
          headerBackgroundColor: data.headerBackgroundColor,
          headerTextColor: data.headerTextColor,
          textColor: data.textColor,
        },

        // datetime for compatibility with old mdp
        datetime: new Date().toISOString(),

        // branded contents
        imprint,
        contact,
        instruction,
        privacy,
        terms,
      }
    );

    // save data
    localStorage.setItem('branding', JSON.stringify(ciData));
    this.subject.next(ciData);
  }
}
