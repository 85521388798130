import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialDesignModule } from './shared/modules/material-design/material-design.module';
import { EopIconModule } from './shared/components/eop-icon/eop-icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CanActivatePreparePaymentGuard } from './shared/guards/can-activate-prepare-payment.guard';
import { ROUTES } from './app.routes';
import { ComponentsModule } from './components/components.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DynamicContentsService } from './shared/services/dynamic-contents.service';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { StationDataService } from './shared/services/station-data.service';
import { SessionService } from './shared/services/session.service';
import { LoadingIndicatorService } from './shared/services/loading-indicator.service';
import { PageHistoryService } from './shared/services/page-history.service';
import { SidenavService } from './shared/services/sidenav.service';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { MdpMissingTranslationHandler } from './shared/services/mdpMissingTranslationHandler';
import { GoogleAnalyticsService } from './shared/services/google-analytics/google-analytics.service';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { register } from 'swiper/element/bundle';

const getBrowserLang = () => {
  let lang = 'en';
  if (window.navigator !== undefined && window.navigator.languages.length > 0) {
    lang = window.navigator.language;
  }
  return lang?.split('-')[0];
};
@NgModule({
  declarations: [AppComponent, ErrorDialogComponent, DialogComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES),
    BrowserAnimationsModule,
    MaterialDesignModule,
    EopIconModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    // configure the imports
    HttpClientModule,
    NgxStripeModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MdpMissingTranslationHandler,
      },
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    MaterialDesignModule,
    EopIconModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorDialogComponent,
  ],
  providers: [
    DynamicContentsService,
    StationDataService,
    CanActivatePreparePaymentGuard,
    SessionService,
    LoadingIndicatorService,
    PageHistoryService,
    SidenavService,
    GoogleAnalyticsService,
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManager,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        const lang = getBrowserLang();

        return registerLocale(lang);
      },
      deps: [],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: getBrowserLang(), deps: [APP_INITIALIZER] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { register(); }
}

function registerLocale(lang: string) {
  return import(`/node_modules/@angular/common/locales/${lang}.mjs`).then(deviceLocale => {
    registerLocaleData(deviceLocale.default);
  });
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
