export interface StationDataInterface {
  accessibilityLabel: string;
  name: string;
  cpoId: number;
  openingHours: OpeningHours[];
  provider: Provider;
  currency: string;
  vat: number;
  ci: CI;
  address: Address;
  chargePoints: ChargePoint[];
  revocationWaiverMandatory: boolean;
}

export interface Provider {
  id: string;
  name: string;
  street: string;
  city: string;
  country: string;
  zipCode: string;
}

export interface OpeningHours {
  open: {
    day: string;
    time: Date;
  };
  close: {
    day: string;
    time: Date;
  };
}

export interface CI {
  logoData: string;
  logoContentType: string;
  terms: {};
  instruction: {};
  imprint: {};
  privacy: {};
  contact: {};
  headerBackgroundColor: string;
  headerTextColor: string;
  textColor: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
}

export interface Address {
  street: string;
  zipCode: string;
  city: string;
  region: string | null; // Not used
  country: string; // Not used
  latitude: string; // Not used
  longitude: string; // Not used
}

export interface ChargePoint {
  name: string;
  status: string;
  evseStatus: string;
  evseDetailedStatus: string; // Not used
  available: boolean;
  plugType: string;
  maxChargingPower: string;
  maxChargingCurrent: string; // Not used
  freeChargingAvailable: boolean; // Not used
  multitenantDirectPaymentAvailable: boolean;
  paymentMethods: PAYMENT_METHODS[];
  preAuthAmount: string;
  tariff: Tariff;
}

export interface Tariff {
  id: string;
  token: string;
  currency: string;
  currentType: string;
  priceComponents: PriceComponent[];
  invalidationTimestamp: Date;
}

export interface PriceComponent {
  priceUnit: TARIFF_TYPES;
  price: number;
  taxAmount: number;
  freeParkingCostMinutes?: number;
}

export enum PAYMENT_METHODS {
  PAYONE_PAYPAL = 'PAYONE_PAYPAL',
  PAYONE_CREDITCARD = 'PAYONE_CREDITCARD',
  STRIPE_CARD = 'STRIPE_CARD',
  STRIPE_PAYPAL = 'STRIPE_PAYPAL',
  STRIPE_AMAZON_PAY = 'STRIPE_AMAZON_PAY',
  STRIPE_APPLE_PAY = 'STRIPE_APPLE_PAY',
  STRIPE_GOOGLE_PAY = 'STRIPE_GOOGLE_PAY',
}

export enum TARIFF_TYPES {
  CHARGING_TIME = 'CHARGING_TIME',
  CHARGING_SESSION = 'CHARGING_SESSION',
  CHARGING_KWH = 'CHARGING_KWH',
  PARKING_TIME = 'PARKING_TIME',
  PARKING_SESSION = 'PARKING_SESSION',
}

export enum PLUGTYPES {
  'AVCON_CONNECTOR' = 'ccs-type-1',
  'CCS_COMBO_1_PLUG_CABLE_ATTACHED' = 'ccs-type-1',
  'CCS_COMBO_2_PLUG_CABLE_ATTACHED' = 'ccs-type-2',
  'CEE_125A' = 'cee',
  'CEE_63A' = 'cee',
  'CH_ADE_MO' = 'chademo',
  'GB_T_AC' = 'gbt',
  'GB_T_DC' = 'gbt',
  'IEC_60309_SINGLE_PHASE' = 'cee',
  'IEC_60309_THREE_PHASE' = 'cee',
  'LARGE_PADDLE_INDUCTIVE' = 'unknown',
  'NEMA_5_20' = 'unknown',
  'POWERLOCK_400A' = 'powerlock',
  'POWERLOCK_630A' = 'powerlock',
  'SMALL_PADDLE_INDUCTIVE' = 'type-2',
  'TESLA_CONNECTOR' = 'unknown',
  'TYPE_1_CONNECTOR_CABLE_ATTACHED' = 'type-1',
  'TYPE_2_CONNECTOR_CABLE_ATTACHED' = 'type-2',
  'TYPE_2_OUTLET' = 'type-2',
  'TYPE_3_OUTLET' = 'type-3',
  'TYPE_E_FRENCH_STANDARD' = 'unknown',
  'TYPE_F_SCHUKO' = 'unknown',
  'TYPE_G_BRITISH_STANDARD' = 'unknown',
  'TYPE_J_SWISS_STANDARD' = 'unknown',
  'UNSPECIFIED' = 'unknown',
}

export enum PLUGTYPE_NAMES {
  'AVCON_CONNECTOR' = 'CCS 1',
  'CCS_COMBO_1_PLUG_CABLE_ATTACHED' = 'CCS 1',
  'CCS_COMBO_2_PLUG_CABLE_ATTACHED' = 'CCS 2',
  'CEE_125A' = 'CEE 125A',
  'CEE_63A' = 'CEE 63A',
  'CH_ADE_MO' = 'CHAdeMO',
  'GB_T_AC' = 'GB/T AC',
  'GB_T_DC' = 'GB/T DC',
  'IEC_60309_SINGLE_PHASE' = 'UNKNOWN',
  'IEC_60309_THREE_PHASE' = 'UNKNOWN',
  'LARGE_PADDLE_INDUCTIVE' = 'UNKNOWN',
  'NEMA_5_20' = 'UNKNOWN',
  'POWERLOCK_400A' = 'Powerlock 400A',
  'POWERLOCK_630A' = 'Powerlock 630A',
  'SMALL_PADDLE_INDUCTIVE' = 'UNKNOWN',
  'TESLA_CONNECTOR' = 'Tesla',
  'TYPE_1_CONNECTOR_CABLE_ATTACHED' = 'Type 1',
  'TYPE_2_CONNECTOR_CABLE_ATTACHED' = 'Type 2',
  'TYPE_2_OUTLET' = 'Type 2',
  'TYPE_3_OUTLET' = 'Type 3',
  'TYPE_E_FRENCH_STANDARD' = 'UNKNOWN',
  'TYPE_F_SCHUKO' = 'UNKNOWN',
  'TYPE_G_BRITISH_STANDARD' = 'UNKNOWN',
  'TYPE_J_SWISS_STANDARD' = 'UNKNOWN',
  'UNSPECIFIED' = 'UNKNOWN',
}
